import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import {
  ConfirmationService,
  LazyLoadEvent,
  MessageService,
} from 'primeng/api';
import { Dropdown } from '../../models/dropdown';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-list-medical-certificate-sended',
  templateUrl: './list-medical-certificate-sended.component.html',
  styleUrls: ['./list-medical-certificate-sended.component.scss']
})
export class ListMedicalCertificateSendedComponent implements OnInit {
  public loading: boolean;
  public atestados: any[] = [];
  public totalRecords: number;
  public optionsStatusMed: Dropdown[];
  public optionsStatusRh: Dropdown[];
  public statusMedValue: any;
  public statusRhValue: any;
  public showModal: boolean;
  public imgSrc: string;
  public navbarVisible: boolean;
  public visible: boolean;
  constructor(
    public api: ApiService,
    private messageService: MessageService,
    public storageService: StorageService,
    public utilsService: UtilsService,
    private navbarService: NavbarService
  ) { }

  ngOnInit(): void {

    this.imgSrc = '../../assets/img/photo-camera.png';
    this.showModal = false;
    this.loading = true;
    this.optionsStatusRh = [
      {
        name: 'ATIVO',
        code: ' ',
      },
      {
        name: 'DEMITIDO',
        code: 'D',
      },
      {
        name: 'FÉRIAS',
        code: 'F',
      },
      {
        name: 'AFASTADO',
        code: 'A',
      },
    ];

    this.optionsStatusMed = [
      { name: 'Aprovado', code: 'A' },
      { name: 'Reprovado', code: 'R' }
    ]
    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  getSeverity(status: string) {
    const fromTo = {
      A: 'success',
      R: 'danger',
      P: 'warning',
    };

    return fromTo[status];
  }

  async downloadAtestado(atestado: any) {

    try {
      if (atestado.foto === "" || atestado.foto === null || atestado.foto === undefined) {
        this.messageService.add({
          severity: 'info',
          summary: 'Atestado',
          detail: "Padrão de documento recebido, não é suportado pelo sistema! Por favor solicite o reenvio",
          life: 8000
        });
      } else {
        switch (atestado.mime_type) {
          case 'image/jpeg':
            this.loading = true;
            try {
              let { token } = this.storageService.getUserLogado();
              let url = `${this.api.urlApi}/aws/s3img/5/8764?img=${atestado.foto}&token=${token}`;
              const base64Img: any = await this.utilsService.convertImageToBase64(url);
              await this.utilsService.formatImgForPdf(base64Img, atestado);
              this.loading = false;
            } catch (error) {
              this.loading = false;

            }
            break;
          case 'image/png':
            this.loading = true;
            try {
              let { token } = this.storageService.getUserLogado();
              let url = `${this.api.urlApi}/aws/s3img/5/8764?img=${atestado.foto}&token=${token}`;
              const base64Img: any = await this.utilsService.convertImageToBase64(url);
              await this.utilsService.formatImgForPdf(base64Img, atestado);
              this.loading = false;
            } catch (error) {
              this.loading = false;

            }
            break;

          case 'image/heif':
            this.loading = true;
            try {
              let { token } = this.storageService.getUserLogado();
              let url = `${this.api.urlApi}/aws/s3img/5/8764?img=${atestado.foto}&token=${token}`;
              const base64Img: any = await this.utilsService.convertImageToBase64(url);
              await this.utilsService.formatImgForPdf(base64Img, atestado);
              this.loading = false;
            } catch (error) {
              this.loading = false;
            }
            break;

          default:
            this.loading = true;
            try {
              let { token } = this.storageService.getUserLogado();
              let url = `${this.api.urlApi}/aws/s3img/5/8764?img=${atestado.foto}&token=${token}`;

              const doc = await this.utilsService.getAtestadoAws(atestado, url);
              if (!doc.status) {
                this.messageService.add({
                  severity: 'info',
                  summary: 'Atestado',
                  detail: doc.message,
                  life: 8000
                });
              }
              this.loading = false;
            } catch (error) {
              this.loading = false;
            }
            break;
        }

      }

    } catch (error) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Ops',
        detail:
          'Error ao abrir pdf',
      });
    }

  }

  getAtestados(event: LazyLoadEvent) {
    this.atestados = [];
    this.loading = true;
    if (!event.sortField) {
      event.sortField = 'update_at'
      event.sortOrder = -1
    }
    this.api.getAtestadosFinalizados(event).subscribe({
      next: (res: any) => {
        res.medical_certificates.map((fun: any) => {
          this.atestados.push({
            ...fun,
            items: [{
              sitfolha: fun.usuarios.dados_funcionarios.sitfolha,
              medico: fun.medico,
              crm: fun.crm,
              cid: fun.cid,
              created_at: fun.created_at,
              updated_at: fun.update_at,
              motivo_reprovacao: fun.motivo_reprovacao
            }]
          })
        })

        this.totalRecords = res.totalRecords;
        this.loading = false;


      },
      error: (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary:
            'Ocorreu um erro ao processar a alteração, favor tentar novamente mais tarde',
        });
        console.error(error);
      },
    });
  }
}
